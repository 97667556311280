import enUS from 'antd/locale/en_US'
import frFR from 'antd/locale/fr_FR'
import esES from 'antd/es/locale/es_ES'

export type Locale = 'en' | 'fr' | 'es'
export const antdLocales = {
  en: enUS,
  fr: frFR,
  es: esES,
}
