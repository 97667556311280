import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CAIIcon from '.'

export default function CAIIconThinking({ awaitingResponse }) {
  const { t } = useTranslation()
  const [dots, setDots] = useState(0)

  useEffect(() => {
    let intervalId

    if (awaitingResponse) {
      intervalId = setInterval(() => {
        setDots((prevDots) => (prevDots + 1) % 4)
      }, 500)
    }

    // Clear the interval when xa becomes false
    return () => clearInterval(intervalId)
  }, [awaitingResponse])

  return (
    <div className="cognent_ai_thinking_container d-flex gap-2">
      <div className="cognent_ai_logo">
        <CAIIcon style={{ width: 30, height: 30 }} />
      </div>
      <div>
        {/* Show dots only if dots is non-negative */}
        {t('caiIconThinking.thinking', 'Thinking')}{' '}
        {dots >= 0 ? '.'.repeat(dots) : ''}{' '}
      </div>
    </div>
  )
}
