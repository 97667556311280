export async function hashPassword(password) {
  const msgBuffer = new TextEncoder().encode(password) // Convert the string to buffer
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer) // Hash the password
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

export function generateUniqueSessionValue() {
  const randomString = Math.random().toString(36).substr(2, 9)
  const timestamp = Date.now().toString(36)

  return `${randomString}-${timestamp}`
}

export function correlationId() {
  return Math.random().toString(36).substring(2, 15)
}

export function parseJWT(token = '') {
  if (token === '') {
    console.log('No token provided.')

    return {}
  }
  try {
    const base64Url = token.split('.')[1]

    if (base64Url === undefined) {
      return {}
    }

    const base64 = base64Url.replace('-', '+').replace('_', '/')

    return JSON.parse(atob(base64))
  } catch (e) {
    console.error('Failed to decode JWT:', e)

    return {}
  }
}

const at0 = localStorage.getItem('accessToken') || ''

export const isExpired = (at = at0) => {
  if (at !== '') {
    const { exp } = parseJWT(at) // Assuming you have a parseJwt function to decode the JWT

    if (exp) {
      const currentUnixTime = Math.floor(Date.now() / 1000)

      return exp < currentUnixTime
    } else {
      console.log('No expiration information found in the token.')

      return true
    }
  }

  console.log('No access token found in local storage.')

  return true
}

export const getSTSJWTFromCallbackCode = async (code, platform = 'slack') => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STS_ENDPOINT}/oauth/${platform}/callback?local=${process.env.REACT_APP_NODE_ENV === 'local'}`,
      {
        method: 'POST',
        body: JSON.stringify({
          code,
        }),
      },
    )
    const data = await response.json()
    return { data, status: response?.status }
  } catch (error) {
    console.error('Failed to get STS JWT from callback code:', error)
  }
}

export const postOAuthCallback = async (platform, code, state) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STS_ENDPOINT}/oauth/${platform}/organisation/callback${process.env.REACT_APP_NODE_ENV === 'local' ? '?local=true' : ''}`,
      {
        method: 'POST',
        body: JSON.stringify({
          code,
          state,
        }),
      },
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error(`Failed to post ${platform} organization callback:`, error)
  }
}
