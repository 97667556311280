import { ApolloProvider } from '@apollo/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './assets/scss/main.scss'
import { AuthProvider } from './components/Auth/AuthProvider'
import { MaintenanceWrapper } from './components/Pages/MaintenanceWrapper'
import { apolloClient } from './libs/apollo.lib'
import i18n from './libs/i18n.lib'
import { queryClient } from './libs/react-query.lib'
import './wdyr'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AuthProvider>
              <MaintenanceWrapper>
                <Suspense>
                  <App />
                </Suspense>
                <ToastContainer />
              </MaintenanceWrapper>
            </AuthProvider>
          </I18nextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  </StrictMode>,
)
