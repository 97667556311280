import { t } from 'i18next'

export const BACK_BUBBLE_BUTTON = {
  id: 'back',
  label: 'Up ' + '\u21E7',
}

export const BUBBLE_DATA = {
  buttons: [
    {
      id: 'focus',
      label: t('caiPopup.bubbles.focus.label', 'Focus'),
      scope: 'focus',
      message: t(
        'caiPopup.bubbles.focus.message',
        'What would you like to focus on?',
      ),
      tooltip: t(
        'caiPopup.bubbles.focus.tooltip',
        'work on something in front of you',
      ),
      sub_buttons: [
        {
          id: 'check_in',
          label: t('caiPopup.bubbles.focus.checkIn.label', 'Check-in'),
          message: t(
            'caiPopup.bubbles.focus.checkIn.message',
            'What do you want to assess?',
          ),
          tooltip: t(
            'caiPopup.bubbles.focus.checkIn.tooltip',
            'assessing the situation',
          ),
          sub_buttons: [
            {
              id: 'check_in_wins',
              label: t('caiPopup.bubbles.focus.checkIn.wins.label', 'Wins'),
              message: t(
                'caiPopup.bubbles.focus.checkIn.wins.message',
                'Great, any big wins or accomplishments recently?',
              ),
            },
            {
              id: 'check_in_blockers',
              label: t(
                'caiPopup.bubbles.focus.checkIn.blockers.label',
                'Blockers',
              ),
              message: t(
                'caiPopup.bubbles.focus.checkIn.blockers.message',
                "Let's see, any major blockers or challenges you are currently facing?",
              ),
            },
          ],
        },
        {
          id: 'environment',
          label: t('caiPopup.bubbles.focus.environment.label', 'Environment'),
          message: t(
            'caiPopup.bubbles.focus.environment.message',
            'Which aspect would you rather discuss?',
          ),
          tooltip: t(
            'caiPopup.bubbles.focus.environment.tooltip',
            'evaluate and value your network-express gratitude',
          ),
          sub_buttons: [
            {
              id: 'environment_culture',
              label: t(
                'caiPopup.bubbles.focus.environment.culture.label',
                'Culture',
              ),
              message: t(
                'caiPopup.bubbles.focus.environment.culture.message',
                'How would you describe the overall culture and dynamics of your current work environment?',
              ),
            },
            {
              id: 'environment_organisation',
              label: t(
                'caiPopup.bubbles.focus.environment.organisation.label',
                'Organisation',
              ),
              message: t(
                'caiPopup.bubbles.focus.environment.organisation.message',
                'How would you describe the overall structure and reporting lines within your organisation?',
              ),
            },
          ],
        },
        {
          id: 'goals&tracking',
          label: t('caiPopup.bubbles.focus.goals.label', 'Goals & Tracking'),
          message: t(
            'caiPopup.bubbles.focus.goals.message',
            'Do you want to discuss middle-term goals or better day-to-day organisation?',
          ),
          tooltip: t(
            'caiPopup.bubbles.focus.goals.tooltip',
            'help set and track goals',
          ),
        },
      ],
    },
    {
      id: 'explore',
      label: t('caiPopup.bubbles.explore.label', 'Explore'),
      scope: 'explore',
      message: t(
        'caiPopup.bubbles.explore.message',
        "I'll be happy to help, remember everything you share with me is strictly confidential, what would you like to explore?",
      ),
      tooltip: t(
        'caiPopup.bubbles.explore.tooltip',
        'work on deeper or long term subjects',
      ),
      sub_buttons: [
        {
          id: 'personal',
          label: t('caiPopup.bubbles.explore.personal.label', 'Personal'),
          message: t(
            'caiPopup.bubbles.explore.personal.message',
            'What would you like to be different or better in your personal or relationship situation?',
          ),
          tooltip: t(
            'caiPopup.bubbles.explore.personal.tooltip',
            'navigate personnal and situational',
          ),
        },
        {
          id: 'interpersonal',
          label: t(
            'caiPopup.bubbles.explore.interpersonal.label',
            'Interpersonal',
          ),
          message: t(
            'caiPopup.bubbles.explore.interpersonal.message',
            'Ok, maybe we could start with an example of a recent situation where you felt your interpersonal skills were challenged or fell short?',
          ),
          tooltip: t(
            'caiPopup.bubbles.explore.interpersonal.tooltip',
            'help with personal and situational questions',
          ),
        },
        {
          id: 'skills',
          label: t('caiPopup.bubbles.explore.skills.label', 'Skills'),
          message: t(
            'caiPopup.bubbles.explore.skills.message',
            "What are the key skills or competencies you believe are most crucial for success in your current role or the role you'd like to grow into next?",
          ),
          tooltip: t(
            'caiPopup.bubbles.explore.skills.tooltip',
            'explore and grow',
          ),
        },
      ],
    },
    {
      id: 'regen',
      label: t('caiPopup.bubbles.regen.label', 'Regen'),
      scope: 'default',
      message: t(
        'caiPopup.bubbles.regen.message',
        "Let's take a bit of time to unwind and recharge",
      ),
      tooltip: t(
        'caiPopup.bubbles.regen.tooltip',
        'take a moment to unwind and recharge',
      ),
      sub_buttons: [
        {
          id: 'reflect',
          label: t('caiPopup.bubbles.regen.reflect.label', 'Reflect'),
          message: t(
            'caiPopup.bubbles.regen.reflect.message',
            "Why don't we take a intentional pause for a few minutes to reset and refocus?",
          ),
          tooltip: t(
            'caiPopup.bubbles.regen.reflect.tooltip',
            'emotional checkin ; what is your mental state',
          ),
        },
        {
          id: 'gratitude',
          label: t('caiPopup.bubbles.regen.gratitude.label', 'Gratitude'),
          message: t(
            'caiPopup.bubbles.regen.gratitude.message',
            'Great, are you familiar with the powerful practice of gratitude?',
          ),
          tooltip: t(
            'caiPopup.bubbles.regen.gratitude.tooltip',
            'express positive and maybe send a message',
          ),
        },
        {
          id: 'align',
          label: t('caiPopup.bubbles.regen.align.label', 'Align'),
          message: t(
            'caiPopup.bubbles.regen.align.message',
            'Alignement is about identifying and clarifying your core values and priorities, then seeing how they align with your life and work, would you like to start exploring this?',
          ),
          tooltip: t(
            'caiPopup.bubbles.regen.align.tooltip',
            'explore feelings of dissonance: are you connected or not?',
          ),
        },
        {
          id: 'positiveMindset',
          label: t(
            'caiPopup.bubbles.regen.positiveMindset.label',
            'Positive Mindset',
          ),
          message: t(
            'caiPopup.bubbles.regen.positiveMindset.message',
            'Happy to check in with you today. How have you been feeling lately in terms of your overall energy levels and outlook?',
          ),
          tooltip: t(
            'caiPopup.bubbles.regen.positiveMindset.tooltip',
            'we all need someone on our side! hype-man',
          ),
        },
      ],
    },
  ],
}

export const findParentId = (childId, buttons) => {
  let queue = [{ buttons: buttons, parentId: null }]

  while (queue.length > 0) {
    let { buttons, parentId } = queue.shift()
    for (let button of buttons) {
      if (button.id === childId) {
        return parentId
      }

      if (button.sub_buttons) {
        queue.push({ buttons: button.sub_buttons, parentId: button.id })
      }
    }
  }

  return null
}

export const getSubButtons = (buttonId, buttons) => {
  let queue = [...buttons]

  while (queue.length > 0) {
    let button = queue.shift()
    if (button.id === buttonId) {
      return button.sub_buttons
    }
    if (button.sub_buttons) {
      queue.push(...button.sub_buttons)
    }
  }

  return null
}
