import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from '../../../constants/cai-chat.constant'
import Tooltip from '../../UI/Tooltip'

const BUTTONS = [
  {
    id: 'anonymous',
    title: 'caiPopup.feedbackButton.button.anonymous.title',
    tooltip: 'caiPopup.feedbackButton.button.anonymous.tooltip',
  },
]

if (process.env.REACT_APP_ENV === 'dev') {
  BUTTONS.push({
    id: 'direct',
    title: 'caiPopup.feedbackButton.button.direct.title',
    tooltip: 'caiPopup.feedbackButton.button.direct.tooltip',
  })
}

export default function FeedbackButtons({
  setFlow,
  handleFeedbackButtonsClick,
  disabled,
}) {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [mode, setMode] = useState('')
  const toggleShow = () => setShow((state) => !state)

  const handleClick = async (id) => {
    setMode(id)
    toggleShow()
  }

  const handleSubmit = async () => {
    setFlow(CAI_CHAT_FLOWS.DEFAULT)
    await handleFeedbackButtonsClick(mode)
    toggleShow()
  }

  return (
    <div className="d-flex flex-row gap-2">
      {BUTTONS.map(({ id, title, tooltip }, index) => (
        <Tooltip
          key={title}
          message={
            disabled
              ? 'Please provide a recipient and FeedForward summary before sending.'
              : t(tooltip)
          }
        >
          <span>
            <button
              className="btn"
              style={{
                background: index === 1 ? '#B22222' : 'var(--primary-color)',
                color: 'white',
                minWidth: '120px',
                height: '45px',
                padding: '0.5em 1em',
                fontSize: '1rem',
                border: '1px solid black',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '5px',
                opacity: disabled ? 0.6 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
              }}
              onClick={() => handleClick(id)}
              disabled={disabled}
            >
              {t(title)}
            </button>
          </span>
        </Tooltip>
      ))}
      <Modal show={show} size="sm" centered onHide={toggleShow}>
        <Modal.Body>
          <p className="mt-4 text-center">
            {t(
              'caiPopup.feedbackButton.modalTitle',
              `Please confirm you're ready to submit this FeedForward`,
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            className="px-4 py-2"
            onClick={toggleShow}
          >
            {t('common.button.cancel')}
          </Button>
          <Button
            variant="primary"
            className="ms-3 px-4 py-2"
            onClick={handleSubmit}
          >
            {t('common.button.submit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
