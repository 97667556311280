import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import { CAI_CHAT_FLOWS } from '../../../constants/cai-chat.constant'
import HeaderButton from './HeaderButton'

const IS_PROD = process.env.REACT_APP_ENV === 'prod' || false

type Props = {
  newChat: (flow: string) => void
  flow: string
}

function HeaderButtons(props: Props) {
  const { newChat, flow } = props

  const { t } = useTranslation()

  const { newChatRef, librarianRef, feedFwdRef } = useContext(CaiPopupContext)

  return (
    <>
      {!IS_PROD && (
        <HeaderButton
          tooltipProps={{
            message: t(
              'caiPopup.headerButtons.tooltipFeedback.title',
              'Enter FeedForward mode to record and share feedback.',
            ),
          }}
          label={t(
            'caiPopup.headerButtons.tooltipFeedback.button',
            'FeedForward',
          )}
          ref={feedFwdRef}
          handleClick={() => newChat(CAI_CHAT_FLOWS.FEEDBACK)}
          style={{
            borderColor: '#e9acac',
            background: 'var(--feedback-color)',
            color: 'black',
            opacity: flow === CAI_CHAT_FLOWS.FEEDBACK ? 1 : 0.7,
          }}
        />
      )}

      <HeaderButton
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipConnect.title',
            'Connect with the Librarian that can explain any official policies and benefits.',
          ),
        }}
        label={t('caiPopup.headerButtons.tooltipConnect.button', 'Librarian')}
        ref={librarianRef}
        handleClick={() => newChat(CAI_CHAT_FLOWS.LIBRARIAN)}
        style={{
          borderColor: '#a9eff8',
          background: 'var(--librarian-color)',
          color: 'black',
          opacity: flow === CAI_CHAT_FLOWS.LIBRARIAN ? 1 : 0.7,
        }}
      />

      <HeaderButton
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipNewChat.title',
            'Start a new chat on a different topic.',
          ),
        }}
        label={t('caiPopup.headerButtons.tooltipNewChat.button', 'New Chat')}
        ref={newChatRef}
        handleClick={() => newChat(CAI_CHAT_FLOWS.DEFAULT)}
        style={{
          borderColor: '#4b0dea',
          opacity: flow === CAI_CHAT_FLOWS.DEFAULT ? 1 : 0.7,
        }}
      />
    </>
  )
}

export default React.memo(HeaderButtons)
