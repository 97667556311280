import { streamPrompt } from '../utils/platform'

const useStreamPrompt = (chatReducer, onAwaitingResponseChange) => {
  const onStreamResponse = (data, flow) => {
    onAwaitingResponseChange(false)
    chatReducer(
      {
        type: 'UPDATE_LAST_MESSAGE',
        payload: { response: data.response, references: data.references },
      },
      flow,
    )
  }

  const onStreamOpen = (flow) =>
    chatReducer({ type: 'INIT_RESPONSE', payload: {} }, flow)

  const onStreamEnd = () => onAwaitingResponseChange(false)

  const sendStreamPrompt = async (
    auth,
    roleName,
    scope,
    attachmentsId,
    chatId,
    message,
    hidden,
    messageContext,
    flow,
    endpoint,
    userId,
    organizationId,
  ) => {
    try {
      onAwaitingResponseChange(true)
      await streamPrompt(
        (data) => onStreamResponse(data, flow),
        () => onStreamOpen(flow),
        onStreamEnd,
        auth?.token,
        roleName,
        scope,
        attachmentsId,
        chatId,
        message,
        hidden,
        messageContext,
        endpoint,
        userId,
        organizationId,
      )
    } catch (e) {
      console.log(e)
    }
  }

  return { sendStreamPrompt }
}

export default useStreamPrompt
