import parse from 'html-react-parser'
import { marked } from 'marked'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import copy from '../../assets/icons/copy.svg'
import ImageViewer from '../common/ImageViewer'

export default function ChatBubble({ data }) {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered((state) => !state)

  return (
    <div
      className="border-0 p-3 p-md-3 position-relative"
      style={{
        textAlign: 'left',
        fontFamily: "'Roboto', sans-serif",
        boxShadow: 'var(--chat-shadow)',
        background: 'white',
        borderRadius: '0 25px 25px 25px',
        width: 'fit-content',
        ...(data.type !== 'received' && {
          background: 'var(--color-primary)',
          color: 'white',
          borderRadius: '25px 0 25px 25px',
        }),
        ...(isMobileOnly && { fontSize: 15 }),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseEnter}
    >
      <div className="chat-message">
        {parse(marked.parse(data.message?.replace(/\\n/g, '<br/>')))}

        <p className="mb-0">
          {data.images?.map((d, i) => (
            <ImageViewer
              key={i}
              className="w120 img-thumbnail"
              src={d}
              alt={`image ${i}`}
            />
          ))}
        </p>
      </div>

      {data.type === 'received' && isHovered && (
        <CopyToClipboard
          text={data.message?.replace(/\\n/g, '\n')}
          onCopy={() =>
            toast.info(
              t('agent.chatBubble.copiedToClipboard', 'Copied to clipboard'),
            )
          }
        >
          <button
            className="btn position-absolute"
            style={{ top: 3, right: -40 }}
          >
            <img alt={t('common.button.copy', 'Copy')} src={copy} />
          </button>
        </CopyToClipboard>
      )}
    </div>
  )
}
