import { lazy, useContext, Suspense } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import LanguageSwitcher from 'src/components/common/LanguageSwitcher'
import { AuthContext } from '../components/Auth/AuthProvider'
import LeftSide from '../components/Auth/LeftSide'
import { Spinner } from 'react-bootstrap'

const SignIn = lazy(() => import('../components/Auth/SignIn'))
const SignUp = lazy(() => import('../components/Auth/SignUp'))
const InvitationAuthentication = lazy(
  () => import('../components/Auth/InvitationAuthentication'),
)
const PasswordReset = lazy(() => import('../components/Auth/PasswordReset'))
const Page404 = lazy(() => import('../components/Auth/Page404'))
const StepAuthentication = lazy(
  () => import('../components/Auth/StepAuthentication'),
)
const PasswordResetConfirmation = lazy(
  () => import('../components/Auth/PasswordResetConfirmation'),
)

export default function AuthIndex() {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { userInfo } = useContext(AuthContext)
  const roleName = userInfo?.employeeDetails?.role?.name

  if (localStorage.getItem('accessToken') && roleName) {
    return history.push(`/${roleName.toLowerCase()}-dashboard`)
  }

  return (
    <div
      className="main p-2 py-3 p-xl-5 d-flex flex-column"
      style={{ position: 'relative' }}
    >
      <div
        className="rounded align-items-end"
        style={{
          background: 'var(--color-primary)',
          width: 'fit-content',
          position: 'absolute',
          top: 20,
          right: 20,
        }}
      >
        <LanguageSwitcher />
      </div>

      <div className="body justify-content-center align-items-center d-flex p-0 p-xl-5">
        <div className="container-xxl">
          <div className="row g-0">
            <LeftSide />
            <Suspense
              fallback={
                <div className="col-lg-6 d-flex ">
                  <Spinner className="m-auto" variant="primary" />
                </div>
              }
            >
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/sign-in`}
                component={SignIn}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/sign-up`}
                render={(props) => {
                  // Check if there are any query parameters
                  if (searchParams.toString()) {
                    return <InvitationAuthentication {...props} />
                  }
                  return <SignUp {...props} />
                }}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/password-reset`}
                component={PasswordReset}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/password-reset-confirmation`}
                component={PasswordResetConfirmation}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/2-step-authentication`}
                component={StepAuthentication}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/page-404`}
                component={Page404}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}
