import React from 'react'
import { Button, Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TTSVolume } from './VolumeButton'

async function generate_tts_blob(chunk, language = 'en') {
  const requestData = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text: chunk, language: language }),
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_CARCH_ENDPOINT}/tts`,
      requestData,
    )
    if (response.ok) {
      const audioBase64 = await response.text()
      const audioData = atob(audioBase64)
      const audioBlob = new Uint8Array(audioData.length)
      audioBlob.set(Array.from(audioData, (char) => char.charCodeAt(0)))
      return new Blob([audioBlob], { type: 'audio/mpeg' })
    } else {
      console.error('API request failed:', response.status, response.statusText)
      return null
    }
  } catch (error) {
    console.error(error)
  }
}

function TTSPlayer({ tourOpen = false, messages }) {
  const [newTtsSentences, setNewTtsSentences] = React.useState('')
  const [ttsBlobs, setTtsBlobs] = React.useState([])
  const [audioPlaying, setAudioPlaying] = React.useState(false)
  const [newTtsProcessing, setNewTtsProcessing] = React.useState(false)
  const [audio, setAudio] = React.useState()
  const [ttsEnabled, setTtsEnabled] = React.useState(!!tourOpen)
  const { i18n } = useTranslation()

  const ttsLastIdx = React.useRef(0)
  const chatMessagesRef = React.useRef(messages)

  const playAudioBlob = (blob) => {
    if (!(blob instanceof Blob)) {
      console.log('Not a blob')
      return Promise.reject(new Error('The input is not a Blob.'))
    }
    const objectURL = URL.createObjectURL(blob)
    const tempAudio = new Audio(objectURL)
    setAudio(tempAudio)
    return new Promise((resolve, reject) => {
      tempAudio.addEventListener('ended', () => {
        URL.revokeObjectURL(objectURL)
        resolve()
        setAudioPlaying(false)
        setAudio()
      })
      tempAudio.play().catch((error) => {
        URL.revokeObjectURL(objectURL)
        reject(error)
      })
    })
  }

  React.useEffect(() => {
    const isTtsEnabled = sessionStorage.getItem('caiTtsEnabled')
    setTtsEnabled(false)
    // console.log('---> tts enabled', isTtsEnabled)
    if (isTtsEnabled === null || isTtsEnabled === 'true') {
      setTtsEnabled(true)
      sessionStorage.setItem('caiTtsEnabled', true)
    } else {
      setTtsEnabled(false)
      sessionStorage.setItem('caiTtsEnabled', false)
    }
  }, [])

  React.useEffect(() => {
    if (tourOpen) {
      setTtsEnabled(false)
    }
  }, [tourOpen])

  React.useEffect(() => {
    // do not play on first message init
    if (chatMessagesRef.current.length === 0 && messages.length > 2) {
      chatMessagesRef.current = messages
      return
    }
    chatMessagesRef.current = messages
    if (
      messages.length !== 0 &&
      messages[messages.length - 1].owner !== 'user' &&
      !newTtsProcessing &&
      ttsEnabled
    ) {
      setNewTtsProcessing(true)
    }
  }, [messages])

  // if tts active, process streaming text
  React.useEffect(() => {
    let intervalId

    if (newTtsProcessing && ttsEnabled) {
      // Every 2 seconds, check and process new sentences
      intervalId = setInterval(() => {
        if (chatMessagesRef.current.at(-1)?.owner === 'user') return
        const lastMessage = chatMessagesRef.current.at(-1)?.message
        // If no text changes, assume message finished
        if (lastMessage?.length === ttsLastIdx.current) {
          setNewTtsProcessing(false)
          ttsLastIdx.current = 0
          clearInterval(intervalId)
          return
        }
        const targetText = lastMessage?.slice(ttsLastIdx.current)
        // split by sentences
        const newSentences = targetText?.split(
          /(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<!\d\.)(?<=\.|\?|\n|!)\s?/gm,
        )
        // if last item not sentence, remove it
        const match = newSentences
          ?.at(-1)
          .match(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<!\d\.)(?<=\.|\?|\n|!)\s?/gm)
        if (!match) newSentences?.pop()

        if (newSentences?.length > 0) {
          // if first sentence then process, else process every second sentence
          var newTtsText
          if (!audioPlaying) {
            // no audio playing, play asap
            newTtsText = newSentences[0]
          } else if (newSentences.length > 2) {
            // else start processing at least 2 sentences ahead
            newTtsText = newSentences.join(' ')
          }
          ttsLastIdx.current = ttsLastIdx.current + newTtsText?.length
          setNewTtsSentences(newTtsText)
        }
      }, 1000)
    }

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId)
  }, [newTtsProcessing])

  React.useEffect(() => {
    if (!ttsEnabled && audio) {
      audio.pause()
    } else if (ttsEnabled && audio) {
      audio.play()
    }
  }, [ttsEnabled, audio])

  React.useLayoutEffect(() => {
    const generateBlob = async () => {
      if (newTtsSentences === '') return
      const ttsBlob = await generate_tts_blob(newTtsSentences, i18n.language)
      setTtsBlobs((prevBlobs) => [...prevBlobs, ttsBlob])
    }
    generateBlob()
  }, [newTtsSentences])

  React.useLayoutEffect(() => {
    const playAudio = async () => {
      // play next audio chunk if ready
      if (ttsBlobs.length > 0 && !audioPlaying) {
        setAudioPlaying(true)
        const targetBlob = ttsBlobs[0]
        setTtsBlobs(ttsBlobs.slice(1))
        playAudioBlob(targetBlob)
      }
    }
    playAudio()
  }, [ttsBlobs, audioPlaying])

  // ... (move the other TTS-related useEffect hooks here) ...

  return (
    <Stack className="justify-content-end" direction="horizontal" gap={2}>
      <Button
        className="btn btn-outline-primary p-0 d-flex justify-content-center align-items-center hover-disable"
        style={{ width: 40, height: 40 }}
        onClick={() => {
          setTtsEnabled((prevState) => {
            const newState = !prevState
            sessionStorage.setItem('caiTtsEnabled', newState)
            return newState
          })
        }}
      >
        <i
          className={`${
            ttsEnabled ? 'icofont-volume-down' : 'icofont-volume-mute'
          } fs-4`}
          style={{ color: 'var(--icon-color)' }}
        ></i>
      </Button>
      {ttsEnabled && <TTSVolume audio={audio} />}
    </Stack>
  )
}

export default TTSPlayer
