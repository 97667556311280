import { PropsWithChildren, useEffect, useState } from 'react'
import MaintenancePage from './MaintenancePage'

export const MaintenanceWrapper = ({ children }: PropsWithChildren) => {
  const [isAllowedIP, setIsAllowedIP] = useState(false)

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'

  const fetchIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = (await response.json()) as { ip: string }
      const userIp = data.ip
      const allowedIPs = process.env.REACT_APP_MAINTENANCE_IPS?.split(',')

      setIsAllowedIP(!!allowedIPs?.includes(userIp))
    } catch (error) {
      console.error('Failed to fetch IP address', error)
    }
  }

  useEffect(() => {
    fetchIPAddress()
  }, [])

  if (isMaintenanceMode && !isAllowedIP) {
    return <MaintenancePage />
  }

  return children
}
