import { ReactElement, ReactNode, useState } from 'react'
import {
  Tooltip as BootstrapTooltip,
  OverlayTrigger,
  OverlayTriggerProps,
} from 'react-bootstrap'
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay'
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger'

export type TooltipProps = {
  children?: ReactElement | ((props: OverlayTriggerRenderProps) => ReactNode)
  message?: string
  overlayTriggerProps?: Partial<OverlayTriggerProps>
  sticky?: boolean
  disabled?: boolean
}

function Tooltip(props: TooltipProps) {
  const { children, message, overlayTriggerProps, sticky, disabled } = props

  const [show, setShow] = useState(sticky)

  const overlay = (tooltipProps: OverlayInjectedProps) => (
    <BootstrapTooltip {...tooltipProps}>
      <div className="position-relative text-start">
        {sticky && (
          <button
            className="btn btn-text position-absolute top-0 end-0 p-0 fs-5 text-danger"
            onClick={() => setShow(false)}
          >
            &times;
          </button>
        )}

        <span className="p-1">{message}</span>
      </div>
    </BootstrapTooltip>
  )

  if (!children) {
    return null
  }

  return (
    <OverlayTrigger
      overlay={overlay}
      show={!disabled && show}
      {...overlayTriggerProps}
    >
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip
