import { createContext, PropsWithChildren, useState } from 'react'

type States = {
  message: string
}

type Actions = {
  resetMessage: () => void
  sendMessage: (newMessage: string) => void
}

export type CaiChatContextType = States & Actions

export const CaiChatContext = createContext<CaiChatContextType>({
  message: '',

  resetMessage: () => {},
  sendMessage: () => {},
})

export const CaiChatProvider = ({ children }: PropsWithChildren) => {
  const [message, setMessage] = useState('')

  const sendMessage = (message: string) => setMessage(message)

  const resetMessage = () => setMessage('')

  const value: CaiChatContextType = { message, resetMessage, sendMessage }

  return (
    <CaiChatContext.Provider value={value}>{children}</CaiChatContext.Provider>
  )
}
