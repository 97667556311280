import { ConfigProvider } from 'antd'
import {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import ChatAgent from 'src/components/ChatAgent'
import { antdLocales } from 'src/constants/i18n.constant'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import { AuthContext } from '../../components/Auth/AuthProvider'
import CAIIcon from '../../components/CaiIcon'
import CaiTour from '../../components/CaiPopup/CaiTour'
import HeaderButtons from '../../components/CaiPopup/HeaderButtons'
import Notification from '../../components/Notification'
import Tooltip from '../../components/UI/Tooltip'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import { CaiChatContext } from '../../context/CaiChatContext'
import { NotificationContext } from '../../context/NotificationContext'
import {
  useGetAllTasksAssignedToUserQuery,
  useUpdateCaiInstanceMutation,
  useUpdatePreviousBubbleIdMutation,
} from '../../gql/graphql'
import useStreamPrompt from '../../hooks/useStreamPrompt'
import {
  BACK_BUBBLE_BUTTON,
  BUBBLE_DATA,
  findParentId,
  getSubButtons,
} from '../../utils/bubbles'
import {
  appendMessage,
  createChat,
  createChatMessage,
  endChat,
  getChatData,
  getSummaryChat,
  isActiveChat,
  searchEntity,
  submitFeedback,
  submitReport,
} from '../../utils/platform'
const getFirstCaiIntro = (userName) => {
  return `Hi ${userName}, nice to meet you! I am Cai, your personal AI Coach. I'm always available to provide you with assistance and information related to the company or the MyCoach platform. How are you going today?`
}

const getSubsequentCaiIntro = (userName, langCode = 'en') => {
  const phrases = {
    en: [
      `Hello ${userName}! Ready to assist….`,
      `Standing by if you need anything, ${userName}!`,
      `Greetings ${userName}! How can I help?`,
      `Welcome back, ${userName}! Need anything?`,
      `Hi there ${userName}! Got a task for me?`,
      `Good to see you, ${userName}! Questions?`,
      `Hello ${userName}! I'm here to help.`,
      `Hi ${userName}! What's on the agenda?`,
      `Greetings ${userName}! How may I assist?`,
      `Hello ${userName}! Ready when you are.`,
      `Hi there ${userName}! What's up?`,
      `Greetings ${userName}! What's our focus?`,
      `Hello ${userName}, can I support in any way?`,
      `Welcome back ${userName}! What's next?`,
      `Welcome back, ${userName}!`,
      `Good to see you, ${userName}!`,
      `Hi ${userName}, I'm here! Need help?`,
    ],
    fr: [
      `Bonjour ${userName}! Prêt à assister….`,
      `Je suis là si tu as besoin de quelque chose, ${userName}!`,
      `Salutations ${userName}! Comment puis-je aider?`,
      `Bon retour, ${userName}! Besoin de quelque chose?`,
      `Salut ${userName}! Une tâche pour moi?`,
      `Content de te revoir, ${userName}! Des questions?`,
      `Bonjour ${userName}! Je suis là pour aider.`,
      `Salut ${userName}! Quel est le programme?`,
      `Salutations ${userName}! Comment puis-je vous assister?`,
      `Bonjour ${userName}! Prêt quand tu l'es.`,
      `Salut ${userName}! Quoi de neuf?`,
      `Salutations ${userName}! Quel est notre objectif?`,
      `Bonjour ${userName}, puis-je aider de quelque manière que ce soit?`,
      `Bon retour ${userName}! Quelle est la suite?`,
      `Bon retour, ${userName}!`,
      `Content de te revoir, ${userName}!`,
      `Salut ${userName}, je suis là! Besoin d'aide?`,
    ],
    es: [
      `¡Hola ${userName}! Listo para ayudar...`,
      `¡Estoy aquí si necesitas algo, ${userName}!`,
      `¡Saludos ${userName}! ¿Cómo puedo ayudar?`,
      `¡Bienvenido de nuevo, ${userName}! ¿Necesitas algo?`,
      `¡Hola ${userName}! ¿Tienes alguna tarea para mí?`,
      `¡Me alegro de verte, ${userName}! ¿Preguntas?`,
      `¡Hola ${userName}! Estoy aquí para ayudar.`,
      `¡Hola ${userName}! ¿Cuál es la agenda?`,
      `¡Saludos ${userName}! ¿En qué puedo ayudarte?`,
      `¡Hola ${userName}! Listo cuando tú lo estés.`,
      `¡Hola ${userName}! ¿Qué hay de nuevo?`,
      `¡Saludos ${userName}! ¿Cuál es nuestro enfoque?`,
      `Hola ${userName}, ¿puedo ayudarte en algo?`,
      `¡Bienvenido de nuevo ${userName}! ¿Qué sigue?`,
      `¡Bienvenido de nuevo, ${userName}!`,
      `¡Me alegro de verte, ${userName}!`,
      `¡Hola ${userName}, estoy aquí! ¿Necesitas ayuda?`,
    ],
  }

  const selectedPhrases = langCode.includes('fr')
    ? phrases['fr']
    : langCode.includes('es')
      ? phrases['es']
      : phrases['en']

  const randomPhrase =
    selectedPhrases[Math.floor(Math.random() * selectedPhrases?.length)]
  return randomPhrase
}

function CaiChat({ handleShow, handleFullSize = (_) => {}, fullScreen }) {
  const { t, i18n } = useTranslation()
  //utils
  const history = useHistory()

  //stores
  const { userInfo, auth, setUserInfo } = useContext(AuthContext)
  const { message, resetMessage } = useContext(CaiChatContext)
  const userId = userInfo?.id
  const { sessionId, handleCompleteActivity } = useContext(NotificationContext)
  const { flow, setFlow, sizeBtnRef, draggingBtnRef } =
    useContext(CaiPopupContext)

  //states
  const [promptContext, setPromptContext] = useState()
  const [awaitingResponse, setAwaitingResponse] = useState(false)
  const [currentBubbles, setCurrentBubbles] = useState(BUBBLE_DATA.buttons)

  const [bubblesLoading, setBubblesLoading] = useState(false)
  const [messagesMap, setMessagesMap] = useState({
    main: [],
    librarian: [],
    feedback: [],
    session: [],
  })
  const [initialFeedback, setInitialFeedback] = useState('')
  const [initialFeedbackLoading, setInitialFeedbackLoading] = useState(false)
  const [tourOpen, setTourOpen] = useState(false)
  const [audioEnabled, setAudioEnabled] = useState(true)

  //apis
  const [updateCaiInstance] = useUpdateCaiInstanceMutation()
  const [updatePreviousBubbleId] = useUpdatePreviousBubbleIdMutation()
  const { data: tasks, loading: tasksLoading } =
    useGetAllTasksAssignedToUserQuery({ variables: { userId } })

  //vars
  const hasInitialised = useRef(false)
  const IsDraggable = fullScreen ? Fragment : Draggable
  const previousBubbles = useRef([])
  const agentScope = useRef(userInfo?.caiInstance?.currentScope)
  const chatId = useRef()
  const userName = useRef('friend')
  const tasksString = useRef('')
  const activeBubble = useRef('default')
  const chatSessionData = useRef({})
  const agentRole = 'cai'
  const chatIds = useRef({
    main: userInfo?.caiInstance?.chatId,
    librarian: null,
    feedback: null,
    session: null,
  })

  //funcs
  const messagesReducer = (action, flow = CAI_CHAT_FLOWS.DEFAULT) => {
    switch (action.type) {
      case 'INIT_RESPONSE': {
        const newMessage = { message: '', type: 'received', owner: agentRole }
        setMessagesMap((state) => ({
          ...state,
          [flow]: [...state[flow], newMessage],
        }))
        break
      }
      case 'ADD_MESSAGE': {
        setMessagesMap((state) => ({
          ...state,
          [flow]: [...state[flow], action.payload],
        }))
        break
      }
      case 'UPDATE_LAST_MESSAGE': {
        setMessagesMap((prevState) => {
          const updatedState = { ...prevState }
          if (updatedState[flow].length) {
            const lastMessageIndex = updatedState[flow].length - 1
            updatedState[flow][lastMessageIndex] = {
              ...updatedState[flow][lastMessageIndex],
              message:
                updatedState[flow][lastMessageIndex].message +
                action.payload.response,
              references: action.payload.references.map((reference) => ({
                ...reference,
                uri:
                  reference.uri === 'interface_guide.md'
                    ? `${process.env.REACT_APP_ASSETS_ENDPOINT}/static/files/product_guides/${reference.uri}`
                    : `${process.env.REACT_APP_ASSETS_ENDPOINT}/documents/${userInfo?.organization?.id}/${reference.uri}`,
              })),
            }
          }
          return updatedState
        })
        break
      }
      default:
        break
    }
  }

  const { sendStreamPrompt } = useStreamPrompt(
    messagesReducer,
    setAwaitingResponse,
  )

  const formatTime = (isoString) => {
    const date = new Date(isoString)
    return date.toLocaleString('en-US', { timeZoneName: 'short' })
  }

  const onInputTyping = () =>
    setCurrentBubbles((state) => state.filter((button) => button.persistent))

  const onReport = async (details) =>
    submitReport(
      auth?.token,
      userInfo.stsUserId,
      chatIds.current[flow],
      details,
    )

  const onMessageSend = async (txtMessage, hidden = false) => {
    if (!hidden) {
      const newMessage = appendMessage(txtMessage, 'sent', 'user')
      messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage }, flow)
    }
    const chatId = await getChatId()
    let currentPromptContext = promptContext
    if (agentScope.current === 'focus') {
      currentPromptContext = `${tasksString.current}\n${currentPromptContext}`
    }
    if (activeBubble.current == 'official_save') {
      await handleBubblePrompt(activeBubble.current, txtMessage)
    } else {
      await sendStreamPrompt(
        auth,
        agentRole,
        agentScope.current,
        [userInfo?.organization?.id],
        chatId,
        txtMessage,
        hidden,
        currentPromptContext,
        flow,
        sessionId && flow === CAI_CHAT_FLOWS.SESSION
          ? `/prompt/session/${sessionId}`
          : undefined,
        userId,
        userInfo?.organization?.id,
      )
    }
    setAwaitingResponse(false)
  }

  const doUpdateCaiInstance = async (chatId, currentScope, bubbleId = 1) => {
    const input = {
      id: userInfo?.caiInstance?.id,
      chatId,
      cogarchRole: userInfo?.caiInstance?.cogarchRole,
      currentScope: currentScope,
    }

    // only set this property when bubbleId = null ==> that mean you want to reset bubbleId in database to null
    if (bubbleId === null) input.bubbleId = null

    await updateCaiInstance({
      variables: { userId, input },
      onCompleted: (data) => {
        setUserInfo({
          ...userInfo,
          caiInstance: data.updateCaiInstance.data.caiInstance,
        })
      },
    })
  }

  const doUpdateCaiInstanceBubbleId = async (bubbleId) => {
    return await updatePreviousBubbleId({
      variables: {
        userId,
        caiInstanceId: userInfo?.caiInstance?.id,
        bubbleId: bubbleId,
      },
      onCompleted: (data) => {
        setUserInfo({
          ...userInfo,
          caiInstance: data.updatePreviousBubbleId.data.caiInstance,
        })
      },
    })
  }

  const setScopeContext = () => {
    const now = new Date()
    const currentTime = formatTime(now.toISOString()) // Use the formatTime function
    let context = `User's name: ${userName.current}\nCurrent time: ${currentTime}\n\n`
    setPromptContext(context)
  }

  const createChatId = async (flow) => {
    const chat = await createChat(auth?.token, userInfo?.stsUserId)
    chatIds.current[flow] = chat?.id
    return chat?.id
  }

  const newChat = async (selectedFlow = CAI_CHAT_FLOWS.DEFAULT) => {
    switch (selectedFlow) {
      case CAI_CHAT_FLOWS.DEFAULT: {
        // set new flow
        setFlow(CAI_CHAT_FLOWS.DEFAULT)
        // if no messages, nothing to do
        if (messagesMap[selectedFlow].length <= 1) return
        // save previous session details
        const previousChatId = chatIds.current[selectedFlow]
        const previousScope = agentScope.current
        // begin new session
        const caiIntro = getSubsequentCaiIntro(userName.current, i18n.language)
        await resetChatSession(caiIntro, selectedFlow)
        const newChatId = await createChatId(selectedFlow)
        await createChatMessage(auth?.token, newChatId, caiIntro, 'cai')
        await doUpdateCaiInstance(newChatId, agentScope.current, null)
        // cleanup old session
        endChat(auth?.token, previousChatId, agentRole, previousScope)
        return
      }
      case CAI_CHAT_FLOWS.FEEDBACK: {
        setFlow(CAI_CHAT_FLOWS.FEEDBACK)
        if (isActiveChat(messagesMap)) {
          setInitialFeedbackLoading(true)
          await getSummaryChat(
            auth?.token,
            chatIds.current[CAI_CHAT_FLOWS.DEFAULT],
          )
            .then((summary) => {
              setInitialFeedback(summary)
            })
            .finally(() => setInitialFeedbackLoading(false))
        }
        return
      }
      case CAI_CHAT_FLOWS.LIBRARIAN: {
        await createChatId(CAI_CHAT_FLOWS.LIBRARIAN)
        setFlow(CAI_CHAT_FLOWS.LIBRARIAN)
        agentScope.current = 'questions'
        return
      }
      case CAI_CHAT_FLOWS.SESSION: {
        setFlow(selectedFlow)
        return
      }
      default: {
        toast.error(t('common.toast.error'))
        console.error('Invalid selectedFlow:', selectedFlow)
      }
    }
  }

  const getChatId = async () => {
    if (!chatIds.current[flow]) await createChatId(flow)
    return chatIds.current[flow]
  }

  function createMessage(message, type, owner) {
    let d = new Date()
    let amPm = 'AM'
    if (d.getHours() >= 12) {
      amPm = 'PM'
    }
    const hour = d.getHours() % 12 || 12
    return {
      message: message,
      type: type,
      images: [],
      owner: owner,
      time: `${hour}:${d.getMinutes()} ${amPm}`,
    }
  }

  const handleBubblesClick = async (bubbles) => {
    setBubblesLoading(true)
    activeBubble.current = bubbles.id
    if (bubbles.scope) {
      agentScope.current = bubbles.scope
      await doUpdateCaiInstance(chatIds.current[flow], bubbles.scope)
      setScopeContext(bubbles.scope)
    }

    let currentBubblesUpdated = []
    // set next bubbles if any
    if (bubbles.sub_buttons) {
      previousBubbles.current = [...previousBubbles.current, currentBubbles]
      currentBubblesUpdated = [...bubbles.sub_buttons, BACK_BUBBLE_BUTTON]
    } else if (bubbles.id === 'back') {
      currentBubblesUpdated = [...previousBubbles.current].pop()
      previousBubbles.current = previousBubbles.current.slice(0, -1)
    } else if (!bubbles.persistent) {
      currentBubblesUpdated = []
    }
    setCurrentBubbles(currentBubblesUpdated)

    if (bubbles.id === 'back') {
      const bubbleId =
        currentBubblesUpdated?.length > 0
          ? findParentId(currentBubblesUpdated?.[0]?.id, BUBBLE_DATA.buttons)
          : null
      await doUpdateCaiInstanceBubbleId(bubbleId)
    } else {
      await doUpdateCaiInstanceBubbleId(
        bubbles?.sub_buttons?.length > 0 ? bubbles.id : null,
      )
    }

    if (bubbles.link) {
      // follow link
      history.push(
        `${userInfo.employeeDetails.role.name.toLowerCase()}-dashboard`,
      )
    } else if (bubbles.message || bubbles.images) {
      // else show bubble's message
      const userMessage = appendMessage(bubbles.label, 'sent', 'user')
      messagesReducer({ type: 'ADD_MESSAGE', payload: userMessage }, flow)
      await createChatMessage(
        auth?.token,
        chatIds.current[flow],
        bubbles.label,
        'user',
      )
      const agentMessage = appendMessage(
        bubbles.message,
        'received',
        agentRole,
        bubbles.images,
      )
      messagesReducer({ type: 'ADD_MESSAGE', payload: agentMessage }, flow)
      await createChatMessage(
        auth?.token,
        chatIds.current[flow],
        bubbles.message,
        agentRole,
      )
    }

    await handleBubbleAction(bubbles.id)
    setBubblesLoading(false)
  }

  const handleSendPrompt = async (chatId, message) => {
    if (message) {
      setAwaitingResponse(true)
      const newMessage = appendMessage(message, 'sent', 'user')
      messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage }, flow)

      sendStreamPrompt(
        auth,
        'cai',
        agentScope.current,
        [userInfo?.organization?.id],
        chatId,
        message,
        false,
        promptContext,
        flow,
      )
        .catch((error) => console.log(error))
        .finally(() => {
          resetMessage()
        })
    }
  }

  const resetChatSession = async (firstMessage, currentFlow) => {
    agentScope.current = 'default'
    setInitialFeedback('')
    setMessagesMap((prevState) => ({ ...prevState, main: [] }))
    const newMessage = createMessage(firstMessage, 'received', 'cai')
    messagesReducer(
      { type: 'ADD_MESSAGE', payload: newMessage },
      currentFlow ?? flow,
    )
    setCurrentBubbles(BUBBLE_DATA.buttons)
    previousBubbles.current = []
  }

  // Initialises a chat session
  const initChat = async (message) => {
    if (!hasInitialised.current && chatId) {
      hasInitialised.current = true
      const chatId = await getChatId(flow)
      const chatData = await getChatData(auth?.token, chatId)

      userName.current = `${userInfo?.employeeDetails?.firstName}`
      setScopeContext(agentScope.current)

      if (!chatData?.length) {
        let caiIntro
        if (userInfo?.isFirstLogin) {
          caiIntro = getFirstCaiIntro(userName.current)
        } else {
          caiIntro = getSubsequentCaiIntro(userName.current, i18n.language)
        }
        await resetChatSession(caiIntro)
        await createChatMessage(auth?.token, chatId, caiIntro, 'cai')
      } else {
        chatData.forEach((message) => {
          messagesReducer({ type: 'ADD_MESSAGE', payload: message }, flow)
        })
      }

      handleSendPrompt(chatId, message)
    } else if (hasInitialised.current && chatIds.current[flow]) {
      handleSendPrompt(chatIds.current[flow], message)
    }
  }

  const handleBubbleAction = async (bubbleId) => {
    switch (bubbleId) {
      // (TYLER) MOVING
      case 'official_review': {
        setAwaitingResponse(true)
        getSummaryChat(auth?.token, chatIds.current[flow]).then((summary) => {
          chatSessionData.current.summary = summary
          const newMessage = appendMessage(summary, 'received', 'cai')
          messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage })
          setAwaitingResponse(false)
        })
        break
      }
      case 'official_submit': {
        setCurrentBubbles([])
        setAwaitingResponse(true)
        await submitFeedback(
          auth?.token,
          userId,
          chatSessionData.current.entity.id,
          chatSessionData.current.entity.type,
          chatSessionData.current.summary,
        )
        const message = `Feedback submitted, resetting chat in 5 seconds.`
        const newMessage = appendMessage(message, 'received', 'cai')
        messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage })
        setAwaitingResponse(false)
        setTimeout(async () => {
          await newChat()
        }, 7000)
        break
      }
    }
  }

  const handleBubblePrompt = async (bubbleId, prompt) => {
    switch (bubbleId) {
      case 'official_save': {
        const entity = await searchEntity(
          auth?.token,
          userInfo?.stsUserId,
          prompt,
          userInfo?.organization?.id,
        )
        const message = `I will submit the feedback for ${entity.name}, if correct please press Submit.`
        const newMessage = appendMessage(message, 'received', 'cai')
        messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage })
        chatSessionData.current.entity = entity
        break
      }
    }
  }

  //effects
  useEffect(() => {
    if (!tasksLoading && tasks) {
      const taskBreakdown = tasks.tasksAssignedToUser
        .filter(
          (task) =>
            task.assignee.status === 'IN_PROGRESS' ||
            task.assignee.status === 'TO_DO',
        )
        .map((task) => ({
          name: task.title,
          status: task.assignee.status,
          end_date: formatTime(task.assignee.endDate), // Format the end date
          description: task.description,
        }))
        .reduce(
          (acc, task) => {
            const taskLine = `${task.end_date} ${task.name}: ${task.description}\n`
            if (task.status === 'TO_DO') {
              acc.todo += taskLine
            } else if (task.status === 'IN_PROGRESS') {
              acc.inProgress += taskLine
            }
            return acc
          },
          { todo: 'TODO\n', inProgress: 'IN_PROGRESS\n' },
        )
      tasksString.current = `USER'S TASKS:\n${taskBreakdown.todo}\n${taskBreakdown.inProgress}`
    }
  }, [tasksLoading, tasks])

  // Initialisation logic
  useEffect(() => {
    if (flow === CAI_CHAT_FLOWS.SESSION) return

    if (userInfo?.caiInstance?.previousBubbleId) {
      setCurrentBubbles([
        ...getSubButtons(
          userInfo?.caiInstance?.previousBubbleId,
          BUBBLE_DATA.buttons,
        ),
        BACK_BUBBLE_BUTTON,
      ])
    } else {
      setCurrentBubbles(BUBBLE_DATA.buttons)
    }

    initChat(message)
  }, [flow])

  // Resizing logic
  useLayoutEffect(() => {
    if (!fullScreen) {
      const resizer = document.querySelector('.resizer-left-top')
      const resizable = document.querySelector('.resizable')
      const content = document.querySelector('.content')

      let isResizing = false
      let startX, startY, startWidth, startHeight

      const minWidth = 400
      const maxWidth = window.innerWidth * 0.8 // 80% of the viewport width
      const minHeight = 500
      const maxHeight = window.innerHeight * 0.9 // 90% of the viewport height

      resizer.addEventListener('mousedown', function (e) {
        isResizing = true
        startX = e.clientX
        startY = e.clientY
        startWidth = parseInt(
          document.defaultView.getComputedStyle(resizable).width,
          10,
        )
        startHeight = parseInt(
          document.defaultView.getComputedStyle(resizable).height,
          10,
        )
        e.preventDefault()
      })

      document.addEventListener('mousemove', function (e) {
        if (!isResizing) return

        const width = Math.max(
          minWidth,
          Math.min(startWidth - (e.clientX - startX), maxWidth),
        )
        const height = Math.max(
          minHeight,
          Math.min(startHeight - (e.clientY - startY), maxHeight),
        )

        resizable.style.width = width + 'px'
        resizable.style.height = height + 'px'
        content.style.width = width + 'px'
        content.style.height = height + 'px'
      })

      document.addEventListener('mouseup', function () {
        isResizing = false
      })
    }
  }, [fullScreen])

  useEffect(() => {
    if (userInfo?.isFirstLogin && fullScreen) {
      setTourOpen(true)
    }
  }, [])

  return (
    <Suspense>
      {fullScreen && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}
          {...(!userInfo?.isFirstLogin && { onClick: handleShow })}
        />
      )}

      <IsDraggable {...(!fullScreen && { handle: '.dragIndicator' })}>
        <div
          id="resizable"
          className="resizable position-fixed overflow-hidden d-flex flex-column"
          style={{
            top: fullScreen ? '50%' : 'auto',
            left: fullScreen ? '50%' : 'auto',
            transform: fullScreen ? 'translate(-50%, -50%)' : 'none',
            bottom: fullScreen ? 'auto' : '20px',
            right: fullScreen ? 'auto' : '20px',
            width: fullScreen ? (isMobileOnly ? '100%' : '80%') : '400px',
            height: fullScreen ? (isMobileOnly ? '100%' : '90%') : '85vh',
            backgroundColor: '#fff',
            boxShadow: fullScreen
              ? '0 4px 8px rgba(0, 0, 0, 0.5)'
              : '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            border: isMobileOnly ? '1px solid black' : 'none',
            borderRadius: fullScreen ? '10px' : '8px',
          }}
        >
          {!fullScreen && <div className="resizer resizer-left-top"></div>}

          <div className="content d-flex flex-column">
            <div className="chat-header d-flex justify-content-between align-items-center border-bottom pb-3 px-2 mt-2">
              <div className="d-flex">
                <a
                  href="#!"
                  title=""
                  style={{ marginLeft: 5, marginTop: 5, cursor: 'pointer' }}
                  onClick={handleShow}
                >
                  <CAIIcon
                    isCai
                    style={{
                      width: 45,
                      height: 45,
                    }}
                  />
                </a>
                <div
                  className="ms-3 d-none d-sm-block"
                  style={{ marginTop: 5 }}
                >
                  <h6 className="mb-0">{t('app.caiChat.cai', 'Cai')}</h6>
                  <small className="text-muted">
                    {t(
                      'app.caiChat.description',
                      'Your personal AI assistant!',
                    )}
                  </small>
                </div>
              </div>

              {fullScreen ? (
                <div className="d-flex align-items-center px-sm-3 gap-2">
                  <div className="d-flex gap-1 align-items-center">
                    {!handleCompleteActivity && (
                      <HeaderButtons newChat={newChat} flow={flow} />
                    )}

                    <Notification
                      newChat={newChat}
                      setMessagesMap={setMessagesMap}
                    />
                  </div>

                  {handleFullSize && (
                    <Tooltip message="Reduce window">
                      <i
                        ref={sizeBtnRef}
                        role="button"
                        className="icofont-rounded-expand fs-4 nav-link text-muted d-none d-lg-block"
                        onClick={handleFullSize}
                      ></i>
                    </Tooltip>
                  )}

                  <i
                    role="button"
                    className="icofont-close nav-link text-muted d-lg-block fs-5"
                    onClick={handleShow}
                  ></i>
                </div>
              ) : (
                <div className="d-flex align-items-end gap-1 flex-column mt-2">
                  <div className="d-flex gap-1 position-absolute align-items-center">
                    <HeaderButtons newChat={newChat} flow={flow} />

                    <Notification
                      newChat={newChat}
                      setMessagesMap={setMessagesMap}
                    />
                  </div>

                  <div
                    className="d-flex gap-1 align-items-center"
                    style={{ marginTop: 30 }}
                  >
                    <Tooltip message="Move window">
                      <i
                        ref={draggingBtnRef}
                        className="icofont-drag nav-link text-muted d-none d-lg-block dragIndicator"
                        style={{ cursor: 'grab', fontSize: 18 }}
                      ></i>
                    </Tooltip>

                    <Tooltip message="Reduce window">
                      <i
                        ref={sizeBtnRef}
                        role="button"
                        className="icofont-rounded-expand nav-link text-muted d-none d-lg-block"
                        onClick={handleFullSize}
                        style={{ fontSize: 24 }}
                      ></i>
                    </Tooltip>

                    <i
                      role="button"
                      className="icofont-close nav-link text-muted d-none d-lg-block"
                      onClick={handleShow}
                      style={{ fontSize: 22 }}
                    ></i>
                  </div>
                </div>
              )}
            </div>

            <ChatAgent
              tourOpen={tourOpen}
              promptContext={promptContext}
              messages={messagesMap[flow] || []}
              roleName={agentRole}
              setAwaitingResponse={setAwaitingResponse}
              onInputTyping={onInputTyping}
              awaitingResponse={awaitingResponse}
              moodButtonsEnabled={true}
              currentBubbles={currentBubbles}
              onBubblesClick={(bubbles) => handleBubblesClick(bubbles, flow)}
              onSendMessage={onMessageSend}
              flow={flow}
              setFlow={setFlow}
              setMessagesMap={setMessagesMap}
              onReport={onReport}
              fullScreen={fullScreen}
              bubblesLoading={bubblesLoading}
              initialFeedback={initialFeedback}
              initialFeedbackLoading={initialFeedbackLoading}
              agentScope={agentScope}
            />
            {userInfo?.isFirstLogin && !isMobile ? (
              <ConfigProvider locale={antdLocales[i18n.language]}>
                <CaiTour
                  fullScreen={fullScreen}
                  newChat={newChat}
                  open={tourOpen}
                  setOpen={setTourOpen}
                  didUpdateMsg={awaitingResponse || bubblesLoading}
                  audioEnabled={audioEnabled}
                  setAudioEnabled={setAudioEnabled}
                  onSessionEnd={() => {
                    if (!fullScreen) {
                      handleFullSize(true)
                    }

                    setUserInfo((s) => ({ ...s, isFirstLogin: false }))
                    setFlow(CAI_CHAT_FLOWS.DEFAULT)
                  }}
                />
              </ConfigProvider>
            ) : null}
          </div>
        </div>
      </IsDraggable>
    </Suspense>
  )
}

export default CaiChat
