import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({ uri: process.env.REACT_APP_SERVER_ENDPOINT })

const authLink = setContext((_, prevContext) => {
  const token = localStorage.getItem('accessToken')
  const headers = prevContext.headers as Headers

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
