import PropTypes from 'prop-types'

export default function PanelTitle({ name, message, messageStyle }) {
  return (
    <div className="d-flex flex-column gap-1">
      {name && (
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '17px',
            color: '#333',
            display: 'inline',
          }}
        >
          {name}
        </span>
      )}

      {message && (
        <p style={{ display: 'inline', ...messageStyle }}>{message}</p>
      )}

      {/* {(name || message) && (
        <span
          style={{
            height: '2px',
            background: '#007bff',
            display: 'inline-block',
            width: '100%'
          }}
        />
      )} */}
    </div>
  )
}

PanelTitle.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.object,
}
