import { isEmpty } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import { Organization, useUserOrganizationsQuery } from 'src/gql/graphql'
import { AuthContext } from '../Auth/AuthProvider'
import get from 'lodash/get'
export default function OrganizationSwitcher() {
  const { userInfo, setUserInfo } = useContext<any>(AuthContext)
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | null | undefined
  >(userInfo?.organization)

  const [currentOrgId, setCurrentOrgId] = useState<string | null>(
    localStorage.getItem('organizationId'),
  )

  const { data, loading } = useUserOrganizationsQuery({
    variables: {
      userId: userInfo?.id,
    },
    skip: !userInfo?.id,
  })

  const organizationList = useMemo(() => {
    if (!data) return []
    const { userOrganizations = [] } = data
    if (isEmpty(userOrganizations)) return []
    return userOrganizations
      ?.map((userOrg) => userOrg?.organization)
      .sort((a, b) => {
        const nameA = a?.name || ''
        const nameB = b?.name || ''
        return nameA.localeCompare(nameB)
      })
  }, [data?.userOrganizations])

  useEffect(() => {
    const organisation = get(userInfo, 'organization', {})
    setCurrentOrgId(organisation?.id)
  }, [userInfo])

  useEffect(() => {
    if (!data) return

    const localStorageOrg = organizationList?.find(
      (org) => org?.id === currentOrgId,
    )

    const defaultOrg = data.userOrganizations?.find(
      (userOrg) => userOrg?.isActive,
    )?.organization

    if (!localStorageOrg && !defaultOrg) {
      console.error(
        'Failed to load organizations: No active or stored organization found.',
      )
    }

    if (!localStorageOrg && defaultOrg)
      localStorage.setItem('organizationId', defaultOrg.id)

    setCurrentOrganization(localStorageOrg ?? defaultOrg)
  }, [data, currentOrgId])

  const handleOrganizationChange = (organizationId: string) => {
    localStorage.setItem('organizationId', organizationId)
    setCurrentOrgId(organizationId)
    setUserInfo((prev: any) => ({
      ...prev,
      organization: organizationList?.find((org) => org?.id === organizationId),
    }))
  }

  if (loading) return <Spinner variant="primary" style={{ margin: 'auto' }} />

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        id="dropdown-language"
        className="nav-link d-flex align-items-center"
        style={{ color: 'var(--color-on-primary)' }}
      >
        <div
          className="me-2 border rounded-circle text-center"
          style={{ width: 20, height: 20, fontSize: 13 }}
        >
          {currentOrganization?.name[0]}
        </div>

        <span className="fs-6 d-inline-block">{currentOrganization?.name}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="p-1">
        {data?.userOrganizations?.map((userOrg) => {
          const { organization } = userOrg ?? {}
          const { id, name } = organization ?? {}

          return (
            <Dropdown.Item
              key={id}
              className="rounded"
              onClick={() => id && handleOrganizationChange(id)}
              style={{
                ...(id === currentOrgId && {
                  background: 'var(--primary-color)',
                  color: 'var(--color-on-primary)',
                }),
              }}
            >
              {name}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
