import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'

type Store = {
  sessionId: string
  isSubmitted: boolean

  setSessionId: Dispatch<SetStateAction<string>>
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
  handleCompleteActivity?: () => void
}

export const NotificationContext = createContext<Store>({
  sessionId: '',
  isSubmitted: false,

  setSessionId: () => {},
  setIsSubmitted: () => {},
})

type Props = {
  defaultSessionId?: string
  children: ReactNode
  handleCompleteActivity?: () => void
}

function NotificationProvider(props: Props) {
  const { defaultSessionId = '', children, handleCompleteActivity } = props

  const [sessionId, setSessionId] = useState(defaultSessionId)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const value: Store = {
    sessionId,
    setSessionId,
    handleCompleteActivity,
    isSubmitted,
    setIsSubmitted,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
