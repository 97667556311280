import { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CaiPopupContext } from '../../context/CaiPopupContext'

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation()
  const { languageSelectionRef } = useContext(CaiPopupContext)
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        ref={languageSelectionRef}
        variant="link"
        id="dropdown-language"
        className="nav-link d-flex align-items-center"
        style={{ color: 'var(--color-on-primary)' }}
      >
        <i className="icofont-globe fs-5 me-2"></i>
        <span className="fs-6 d-inline-block">
          {t('common.word.language')}: {i18n.language.toUpperCase()}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage('en')}>
          English
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('fr')}>
          Français
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('es')}>
          Español
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageSwitcher
