import CAIIcon from '.'

export const CAIIconAnim = ({ messages = 'Initialising, please wait...' }) => {
  return (
    <div className="cognent_ai_thinking_container mx-auto my-auto flex-column gap-1">
      <div className="cognent_ai_logo">
        <CAIIcon style={{ width: 30, height: 30 }} />
      </div>
      <div style={{ fontWeight: 600 }}>{messages}</div>
    </div>
  )
}
