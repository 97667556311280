import { ConfigProvider, Slider } from 'antd'
import { FC, useEffect, useState } from 'react'

interface TtsVolumeProps {
  audio: HTMLAudioElement | null
}

export const TTSVolume: FC<TtsVolumeProps> = ({ audio }) => {
  const [volume, setVolume] = useState(0.3)

  const handleVolumeChange = (event: number) => setVolume(event)

  useEffect(() => {
    if (audio) {
      audio.volume = volume
    }
  }, [volume, audio])

  return (
    <div style={{ width: 100 }}>
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              handleSize: 13,
              handleColor: '#622dd3',
              dotActiveBorderColor: '#622dd3',
              handleActiveColor: '#622dd3',
              colorPrimaryBorderHover: '#622dd3',
              trackBg: '#622dd3',
            },
          },
        }}
      >
        <Slider
          min={0}
          max={1}
          step={0.1}
          value={volume}
          onChange={handleVolumeChange}
        />
      </ConfigProvider>
    </div>
  )
}
