import { forwardRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import Tooltip, { TooltipProps } from '../../UI/Tooltip'

type Props = {
  label: string
  handleClick: () => void
  style?: React.CSSProperties
  tooltipProps?: TooltipProps
}

const HeaderButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { handleClick, label, tooltipProps, style, ...rest } = props

  return (
    <Tooltip
      disabled={isMobileOnly}
      overlayTriggerProps={{ placement: 'bottom' }}
      {...tooltipProps}
    >
      <button
        {...rest}
        className="btn btn-primary py-1 px-2 "
        style={{
          fontSize: 13,
          background: 'var(--color-primary)',
          border: '2px solid black',
          borderColor: 'var(--color-primary)',
          fontWeight: 500,
          ...style,
        }}
        ref={ref}
        onClick={handleClick}
      >
        {label}
      </button>
    </Tooltip>
  )
})

export default HeaderButton
